<template>
  <h6 class="text-subtitle2 q-ml-lg">
    {{ $t("login.recover.title") }}
  </h6>
  <channel-options
    v-if="selectChannel"
    :channels="channels"
    :in_request="in_request"
    @selected-channel="sendRecover"
  />
  <Form
    v-else
    class="q-gutter-md"
    :initial-values="{}"
    :validation-schema="schema"
    @submit="onSubmit"
  >
    <q-card-section>
      <Field name="email" v-slot="{ errorMessage, value, field }">
        <q-input
          autocomplete="on"
          filled
          :label="labels.email"
          :placeholder="$t('forms.placeholders.email')"
          :model-value="value"
          v-bind="field"
          :error-message="errorMessage"
          :error="!!errorMessage"
        />
      </Field>
     <div class="row justify-between items-center">
      <q-btn flat label='Voltar' @click="goBack"/>
      <q-btn
        class="q-mt-md q-mb-lg bg-custom-color"
        type="submit"
        :label="$t('login.recover.submit')"
        :loading="in_request"
        :disable="in_request"
      />
     </div>
    </q-card-section>
  </Form>
</template>

<script>
import ChannelOptions from "@/modules/auth/pages/Recover/components/ChannelOptions";
import { useActions } from "vuex-composition-helpers";
import { notifyError } from "@/shared/helpers/notify";
import { rules, labels } from "@/shared/form-presets";
import { useRouter, useRoute } from "vue-router";
import { useReCaptcha } from "vue-recaptcha-v3";
import { Field, Form } from "vee-validate";
import { Notify } from "quasar";
import * as yup from "yup";
import { ref } from "vue";

export default {
  name: "Recover",

  components: {
    ChannelOptions,
    Field,
    Form,
  },

  setup() {
    const in_request = ref(false),
      validation_email = ref(""),
      selectChannel = ref(false),
      channels = ref({}),
      router = useRouter(),
      route = useRoute(),
      schema = (() => {
        const { emailLogin } = rules;
        return yup.object({ email: emailLogin });
      })(),
      { confirmYou, sendRecoveryCode } = useActions({
        confirmYou: "auth/confirmYou",
        sendRecoveryCode: "auth/sendRecoveryCode",
      });

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const goBack = () => {
      router.go(-1)
    }

    const sendRecover = async (channel) => {
        in_request.value = true;

        await recaptchaLoaded();
        let token = await executeRecaptcha("login");

        sendRecoveryCode({
          email: validation_email.value,
          channel: channel,
          slug: route.params.slug,
          recaptcha_response: { token }.token,
        })
          .then(() => {
            router.push({
              name: "recover.confirm.code",
              params: {
                email: validation_email.value,
              },
              query: {
                channel: channel,
              },
            });
          })
          .catch(({ errors, message }) => {
            const notify = (error) => {
              Notify.create({
                message: error,
                position: "top-right",
                color: "red",
                icon: "fas fa-exclamation",
              });
            };
            if (errors) Object.values(errors).forEach(notify);
            if (message) notify(message);
          })
          .finally(() => {
            in_request.value = false;
          });
      },
      onSubmit = (values, actions) => {
        in_request.value = true;
        confirmYou({
          confirmation: values.email,
        })
          .then((data) => {
            validation_email.value = values.email;
            channels.value = data;
            if (data.whatsapp) {
              selectChannel.value = true;
            } else sendRecover("email");
          })
          .catch(() => {
            actions.setFieldValue("email", "");
            notifyError("login.recover.error.confirmation");
          })
          .finally(() => {
            in_request.value = false;
          });
      };

    return {
      goBack,
      in_request,
      selectChannel,
      labels,
      schema,
      channels,
      onSubmit,
      sendRecover,
    };
  },
};
</script>

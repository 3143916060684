<template>
  <div class="q-ma-md">
    <div class="text-subtitle1">
      {{ $t("login.recover.channel_option.header") }}
    </div>
    <div class="full-width card-channels relative-position">
      <q-skeleton
        v-if="in_request"
        class="absolute-top"
        width="100%"
        height="100%"
        animation="wave"
      />
      <div
        v-if="channels.sms"
        @click="selectedChannel('sms')"
        class="row option no-wrap just-content-center q-ma-md cursor-pointer"
      >
        <q-icon name="fas fa-sms" size="2em" class="self-center"></q-icon>
        <div class="q-ma-sm width-fit-content-flex">
          <div class="text-subtitle3 text-weight-bold">
            {{ $t("login.recover.channel_option.sms") }}
          </div>
          <div class="text-subtitle3 width-100">
            {{ $t("login.recover.channel_option.on_sms") }}
            <span class="text-weight-bold">{{ channels.sms }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="channels.whatsapp"
        @click="selectedChannel('whatsapp')"
        class="row option no-wrap just-content-center q-ma-md cursor-pointer"
      >
        <q-icon name="fab fa-whatsapp" size="2em" class="self-center"></q-icon>
        <div class="q-ma-sm width-fit-content-flex">
          <div class="text-subtitle3 text-weight-bold">
            {{ $t("login.recover.channel_option.whatsapp") }}
          </div>
          <div class="text-subtitle3 width-100">
            {{ $t("login.recover.channel_option.on_whatsapp") }}
            <span class="text-weight-bold">{{ channels.whatsapp }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="channels.email"
        class="row option no-wrap just-content-center q-ma-md cursor-pointer"
        @click="selectedChannel('email')"
      >
        <q-icon name="email" size="2em" class="self-center"></q-icon>
        <div class="q-ma-sm width-fit-content-flex">
          <div class="text-subtitle3 text-weight-bold">
            {{ $t("login.recover.channel_option.email") }}
          </div>
          <div class="text-subtitle3 width-100">
            {{ $t("login.recover.channel_option.on_email") }}
            <span class="text-weight-bold">{{ channels.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChannelOptions",

  emits: ["selectedChannel"],

  props: {
    channels: {
      type: Object,
      required: true,
    },
    in_request: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, root) {
    const selectedChannel = (name) => {
      root.emit("selectedChannel", name);
    };
    return {
      selectedChannel,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-channels {
  opacity: 0.7;
  background: #e6e6e6;
  border-radius: 6px;
  color: #616161;
  .option {
    padding: 10px;
    &:hover {
      color: black;
    }
  }
}
</style>
